<template>
	<div class="login-wrap">
		<div class="ms-login">
			<div class="ms-title">校园网络管理系统</div>
			<el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
				<el-form-item prop="用户名">
					<el-input v-model="param.username" placeholder="用户名">
						<template #prepend>
							<el-button icon="el-icon-user"></el-button>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item prop="密码">
					<el-input type="password" placeholder="密码" v-model="param.password" @keyup.enter="submitForm()">
						<template #prepend>
							<el-button icon="el-icon-lock"></el-button>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item prop="code">
					<el-input type="text" v-model="param.code" placeholder="- - - -">
						<template v-slot:prepend>验证码</template>
						<template v-slot:append>
							<div class="login-code" @click="refreshCode">
								<s-Identify :identifyCode="identifyCode"></s-Identify>
							</div>
						</template>
					</el-input>
				</el-form-item>
				<div class="login-btn">
					<el-button type="primary" @click="submitForm()">登录</el-button>
				</div>
				<p class="login-tips"></p>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		loginForm
	} from "../api/login";
	import SIdentify from '@/components/identify'
	export default {
		data() {
			// const validateCode = (rule, value, callback) => {
			// 	if (this.identifyCode !== value) {
			// 		this.loginForm.code = ''
			// 		this.refreshCode()
			// 		callback(new Error('请输入正确的验证码'))
			// 	} else {
			// 		callback()
			// 	}
			// }
			return {
				param: {
					username: "",
					password: "",
					code: ""
				},
				identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
				identifyCode: '',
				rules: {
					username: [{
						required: true,
						message: "请输入用户名",
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: "请输入密码",
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}]
				}
			};
		},
		components: {
			's-Identify': SIdentify,
		},
		watch: {
			identifyCode(v) {
				this.isDebugLogin && (this.loginForm.code = v)
			}
		},
		created() {
			this.$store.commit("clearTags");
			this.refreshCode()
		},
		mounted() {
			// 初始化验证码
			this.identifyCode = ''
			this.makeCode(this.identifyCodes, 4)
		},
		methods: {
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min)
			},
			refreshCode() {
				this.identifyCode = ''
				this.makeCode(this.identifyCodes, 4)
			},
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					this.identifyCode += this.identifyCodes[
						this.randomNum(0, this.identifyCodes.length)
					]
				}
			},
			// 提交登录信息
			submitForm() {
				if (this.param.code.toLowerCase() !== this.identifyCode.toLowerCase()) {
					this.$message.error('请填写正确验证码')
					this.refreshCode()
					return
				}
				this.$refs.login.validate(valid => {
					if (valid) {
						loginForm(this.param).then(res => {
							if (res.code == 200) {
								// console.log(res)
								this.$message.success("登录成功");
								localStorage.setItem("u_name", this.param.username);
								localStorage.setItem("token", res.data.token);
								// localStorage.setItem("token", '1111');
								//localStorage.setItem("myMenu", JSON.stringify(res.data.tree));                        
								this.$router.push("/");
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					} else {
						this.$message.error("请输入账号和密码");
						return false;
					}
				});
			}
			// submitForm() {
			// 	this.$refs.login.validate(valid => {
			// 		if (valid) {
			// 			loginForm(this.param).then(res => {
			// 				if (res.code == 200) {
			// 					// console.log(res)
			// 					this.$message.success("登录成功");
			// 					localStorage.setItem("u_name", this.param.username);
			// 					localStorage.setItem("token", res.data.token);
			// 					//localStorage.setItem("myMenu", JSON.stringify(res.data.tree));                        
			// 					this.$router.push("/");
			// 				} else {
			// 					this.$message.error(res.msg);
			// 					return;
			// 				}
			// 			});
			// 		} else {
			// 			this.$message.error("请输入账号和密码");
			// 			return false;
			// 		}
			// 	});
			// }
		}
	};
</script>

<style>
	.login-wrap {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: url(../assets/img/login-bg.jpg);
		background-size: 100%;
	}

	.ms-title {
		width: 100%;
		line-height: 50px;
		text-align: center;
		font-size: 20px;
		color: #fff;
		border-bottom: 1px solid #ddd;
	}

	.ms-login {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 350px;
		margin: -190px 0 0 -175px;
		border-radius: 5px;
		background: rgba(255, 255, 255, 0.3);
		overflow: hidden;
	}

	.ms-content {
		padding: 30px 30px;
	}

	.login-btn {
		text-align: center;
	}

	.login-btn button {
		width: 100%;
		height: 36px;
		margin-bottom: 10px;
	}

	.login-tips {
		font-size: 12px;
		line-height: 30px;
		color: #fff;
	}

	.el-input-group__append {
		padding: 0 !important;
	}
</style>
