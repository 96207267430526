import request from '../utils/request';

export function loginForm(query) {
    return request({
        url: 'login',
        method: 'post',
        data: query
    });
}

// export function getNav(query) {
//     return request({
//         url: 'get_menu',
//         method: 'get',
//         data: query
//     });
// }
